<template>
	<div style="padding: 15px;background: #fff;" class="Analydetailbox">
		<div class="Analydetailtop">
			<img :src="imgUrl+productData.ImgUrl" style="width: 60px;height: 60px;margin-right:10px;" />
			<div class="detailinfo">
				<div class="title">{{productData.Name}}</div>
				<div class="bottom">
					<div class="bottom_top" style="margin:8px 0 8px 0">
						<span>商品分组：
							<el-tag type="info" style="font-size: 12px;margin-right: 5px;" v-for="(item,index) in GroupNames" :key="index">{{item}}</el-tag>

						</span>
					</div>
					<!-- <div class="bottom_top">
						<span>最近上架时间：2015.2.3</span>
					</div> -->
				</div>
			</div>
		</div>
		<div class="commonTable">
			<div class="title">
				<div class="left">SKU销售分析</div>
				<div class="right">
					<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime'></statistics-time>
				</div>
			</div>
			<div class="table" style="margin-top: 10px;">
				<el-table :data="tableData" style="width: 100%" v-loading="skuLoading" @sort-change="sortChange">
					<el-table-column prop="Price" label="售价(元)"></el-table-column>
					<el-table-column prop="AddToCartProductCount" sortable label="加购件数"></el-table-column>
					<el-table-column prop="AddToCartMemberCount" sortable label="加购人数"></el-table-column>
					<el-table-column prop="SubmitOrderProductCount" sortable label="下单件数"></el-table-column>
					<el-table-column prop="SubmitOrderMemberCount" sortable label="下单人数"></el-table-column>
					<el-table-column prop="SubmitOrderMoney" sortable label="下单金额"></el-table-column>
					<el-table-column prop="AddSubmitConversionRate" sortable label="加购-下单转化率"  width="180">
						<template slot-scope="scope">
							<span>{{scope.row.AddSubmitConversionRate}}</span>
							<span v-if="scope.row.AddSubmitConversionRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderProductCount" sortable label="支付件数"></el-table-column>
					<el-table-column prop="PayOrderMemberCount" sortable label="支付人数"></el-table-column>
					<el-table-column prop="PayOrderMoney" sortable label="支付金额"></el-table-column>
					<el-table-column prop="SubmitPayConversionRate" sortable label="下单-支付转化率"  width="180" >
						<template slot-scope="scope">
							<span>{{scope.row.SubmitPayConversionRate}}</span>
							<span v-if="scope.row.SubmitPayConversionRate>0">%</span>
						</template>
					</el-table-column>
				</el-table>
			<!-- 	<el-pagination style="margin-top:20px;float:right;" v-if="page.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40]" :page-size="page.size"
				 :total="page.total" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination> -->
			</div>
		</div>

		<div class="commonTable">
			<div class="title">
				<div class="left">复购分析</div>
				<div class="right">
					<div class="top">
						<span style="margin-right:10px;">统计周期:</span>
						<el-select v-model="periodId" @change="statisiticChange">
							<el-option v-for="item in periodlist" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
						<span style="margin-left: 10px;color: #999;">统计时间：近一年</span>
					</div>

				</div>
			</div>
			<div class="table" style="margin-top: 10px;">
				<el-table :data="tableData1" style="width: 100%" v-loading="repeatLoading">
					<el-table-column prop="StatisticPeriod" label="统计周期"></el-table-column>
					<el-table-column prop="PayMemberCount" label="总付款人数"></el-table-column>
					<el-table-column prop="PayOnceMemberCount" label="购买一次人数"></el-table-column>
					<el-table-column prop="PayTwiceMemberCount" label="购买两次人数"></el-table-column>
					<el-table-column prop="PayThreeTimesMemberCount" label="购买三次人数"></el-table-column>
					<el-table-column prop="PayFourTimesMemberCount" label="购买四次人数"></el-table-column>
					<el-table-column prop="PayMoreThenFiveTimesMemberCount" label="购买五次人数已以上人数"  width="180"></el-table-column>
					<el-table-column prop="AvgRepeatPayPeriod" label="平均复购周期"></el-table-column>
					<el-table-column prop="RepeatPayRate" label="复购率">
						<template slot-scope="scope">
							<span>{{scope.row.RepeatPayRate}}</span>
							<span v-if="scope.row.RepeatPayRate>0">%</span>
						</template>
					</el-table-column>
				</el-table>
			<!-- 	<el-pagination style="margin-top:20px;float:right;" v-if="page1.total1" @size-change="handleSizeChange1"
				 @current-change="handleCurrentChange1" :current-page="page1.current1" :page-sizes="[10, 20, 30, 40]" :page-size="page1.size1"
				 :total="page1.total1" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination> -->
			</div>
		</div>
	</div>
</template>

<script>
	import statisticsTime from './StatisticsTime'
	import config from '@/config/index'
	import {
		dataStatisProductInfo,
		dataStatisSkuSaleAnalyze,
		dataStatisRepeatPayAnalyze
	} from '@/api/goods'
	export default {
		components: {
			statisticsTime
		},
		data() {
			return {
				periodId: 1,
				periodlist: [{
					value: 2,
					label: '按月'
				}, {
					value: 1,
					label: '按季度'
				}],
				unShowType: [0,1,2,7, 8, 9, 10],
				startDate: '',
				endDate: '',
				timeType: '',
				tableData: [],
				page: {
					size: 20,
					current: 1,
					total: 0
				},
				tableData1: [],
				page1: {
					size1: 20,
					current1: 1,
					total1: 0
				},
				ProductId: '',
				productData: {},
				GroupNames: [],
				imgUrl: config.IMG_BASE,
				skuLoading: false,
				OrderBy: '',
				IsAsc: '',
				repeatLoading:false
			}
		},
		beforeMount() {
			this.ProductId = this.$route.query.SingelAnalyId
			this.getProductInfo()
			// this.skuData()
			this.repeatPayAnalyze()
		},
		methods: {
			// 获取商品信息
			async getProductInfo() {
				try {
					let result = await dataStatisProductInfo({
						ProductId: this.ProductId
					})
					this.productData = result.Result
					this.GroupNames = result.Result.GroupNames
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}

			},

			// sku销售分析
			async skuData() {
				try {
					this.skuLoading = true
					let data = {
						ProductId: this.ProductId,
						TimeType: this.timeType,
						StartTime: this.startDate,
						EndTime: this.endDate
					}

					let result = await dataStatisSkuSaleAnalyze(data)
					this.tableData = result.Result

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.skuLoading = false
				}

			},
			// 复购分析
			async repeatPayAnalyze(){
				try{
					this.repeatLoading=true
					let data={
						ProductId: this.ProductId,
						PeriodType:this.periodId
					}
					let result =await dataStatisRepeatPayAnalyze(data)
					this.tableData1 =result.Result
					
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}finally{
					this.repeatLoading=false
				}
			},
			statisiticChange(){
				 this.repeatPayAnalyze()
			},
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.page.current = 1;
				// this.skuData();
			},
			handleSizeChange(val) {
				this.page.size = val
			},
			handleCurrentChange(val) {
				this.page.current = val
			},
			handleSizeChange1(val) {
				this.page1.size1 = val
			},
			handleCurrentChange1(val) {
				this.page1.current1 = val
			},
			getRangeTime(startDate, endDate, timeType) {
				this.startDate = startDate
				this.endDate = endDate
				this.timeType = timeType
				// console.log(this.startDate, this.endDate, this.timeType, '获取组件参数')
				this.skuData()
			},
		}
	}
</script>

<style lang="less" scoped>
	.Analydetailbox {
		.Analydetailtop {
			display: flex;
			align-items: center;

			.detailinfo {
				.bottom {
					font-size: 12px;
					color: #999;
				}
			}
		}

		.commonTable {
			margin-top: 20px;

			.title {
				font-size: 13px;
				background: #F5F5F5;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					border-left: 2px solid #0040D0;
					padding-left: 5px;
					padding-top: 2px;
				}

				.right {
					.top {
						padding: 0px !important;
						background: none !important;
						font-size: 13px;
					}
				}
			}
		}
	}
</style>
