import { render, staticRenderFns } from "./SingelAnalydetail.vue?vue&type=template&id=efb9d55e&scoped=true"
import script from "./SingelAnalydetail.vue?vue&type=script&lang=js"
export * from "./SingelAnalydetail.vue?vue&type=script&lang=js"
import style0 from "./SingelAnalydetail.vue?vue&type=style&index=0&id=efb9d55e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efb9d55e",
  null
  
)

export default component.exports